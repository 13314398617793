import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { CACHE_STORAGE, CacheStorage } from '@shared/cache';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly cache: CacheStorage;
  private readonly baseUrl: string = `${environment.trigon.mainUrl}`;

  constructor(private httpClient: HttpClient, private auth: AngularFireAuth) {
    this.cache = inject(CACHE_STORAGE);
  }

  signIn({ email, password }: { email: string; password: string }) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    this.cache.clear();
    this.cache.clearRequestContext();
    return this.auth.signOut();
  }

  forgotPassword({ email }): Observable<Object> {
    return this.httpClient
      .post(`${this.baseUrl}/users/forgot`, { email })
      .pipe(catchError(this.handleError));
  }

  signInWithEmailLink(email, url) {
    return this.auth.signInWithEmailLink(email, url);
  }

  sendSignInLinkToEmail(email, actionSettings) {
    return this.auth.sendSignInLinkToEmail(email, actionSettings);
  }

  invite(payload: { email: string }) {
    return this.httpClient
      .post<any>(`${this.baseUrl}/users/invite`, payload)
      .pipe(catchError(this.handleError));
  }

  verify(body: { password: string; confirmPassword: string; legalEntityId: string }) {
    return this.httpClient
      .post(`${this.baseUrl}/users/verify`, body)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
