import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LowerCasePipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  CompanyPopupsService,
  ICompany,
} from '@src/app/_domains/company';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderOverlayDirective } from '@src/app/_shared/global-directives/loader-overlay.directive';
import { TrDatePipe } from '@src/app/_shared/global-pipes/tr-date.pipe';
import { SharedModule } from '@src/app/_shared/shared.module';
import { EInvoiceContractStatusIndicator } from '@src/app/_domains/company/shared/components/e-invoice-contract-status-indicator';
import { EInvoiceContract, EInvoiceContractState, EInvoiceService } from '@src/app/_domains/invoice';

type Entity = EInvoiceContract & {
  current?: boolean;
};

@Component({
  standalone: true,
  selector: 'e-invoice-contract-status',
  imports: [
    NgClass,
    LowerCasePipe,
    TranslateModule,
    EInvoiceContractStatusIndicator,
    MatButtonModule,
    MatTooltipModule,
    LoaderOverlayDirective,
    SharedModule,
  ],
  template: `
    @if (loading) {
      <div [loaderOverlay]="true" loaderIndicatorSize="small">
        <div style="height: 24px; width: 24px"></div>
      </div>
    } @else {
      <button
        (click)="showContractStatusHistory()"
        class="contract-status-btn active no-animate"
      >
        <e-invoice-contract-status-indicator
          [status]="currentContract?.currentState || states.TERMINATED"
          [showLabel]="false"
          [iconSize]="'24px'"
        ></e-invoice-contract-status-indicator>
      </button>
    }
  `,
  styles: [
    `
      .contract-status-btn {
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        width: unset;
        border: none;

        &.bordered {
          border: 1px solid var(--tr-orange);
          border-radius: 4px;
        }
      }

      .icon-rotate {
        color: var(--tr-orange) !important;
        font-size: 24px;
      }

      .icon-arrow-right {
        color: black !important;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EInvoiceContractStatus implements OnInit, OnChanges {
  @Input() company: ICompany;
  loading: boolean = true;
  currentContract: Entity;
  states = EInvoiceContractState;
  trDatePipe = new TrDatePipe();

  constructor(
    private eInoviceService: EInvoiceService,
    private chd: ChangeDetectorRef
  ) {}

  private fetchContractsHistory() {
    this.loading = true;
    this.eInoviceService.getContractById(this.company.id).subscribe((contracts) => {
      this.currentContract = contracts.find(item => item.provider === 'RIK');

      this.loading = false;
      this.chd.detectChanges();
    });
  }

  ngOnInit(): void {
    this.fetchContractsHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.company.currentValue &&
      changes.company.previousValue &&
      changes.company.currentValue?.uuid !== changes.company.previousValue?.uuid
    ) {
      this.reset();
      this.fetchContractsHistory();
    }
  }

  reset() {
    this.currentContract = null;
  }

  showContractStatusHistory(): void {
    this.eInoviceService.showStatusesHistoryPopup(this.company).subscribe(
      res => res && this.ngOnInit()
    );
    
  }
}
