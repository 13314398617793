import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CURRENT_COMPANY_STORE } from '@domains/company';

@Injectable()
export class CompanyIdInterceptor implements HttpInterceptor {
  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly companyID = this.companyStore.id;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(':companyID')) {
      req = req.clone({
        url: req.url.replace(':companyID', this.companyID()),
      });
    }
    return next.handle(req);
  }
}
