import { inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@api/auth.service';
import { NotificationService } from '@shared/notification/notification.service';
import { ServerSideErrorCode } from '@shared/constants';
import { CURRENT_COMPANY_STORE, ICompanyContractStatus } from '../_domains/company';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  currentCompanyState = inject(CURRENT_COMPANY_STORE);
  currentCompany = this.currentCompanyState.entity;

  constructor(
    private authService: AuthService,
    private ntf: NotificationService
  ) {}

  conflictErrorHandling(errResponse: HttpErrorResponse) {
    if (errResponse.error.type) {
      switch (errResponse.error.type) {
        case ServerSideErrorCode.COMPANY_PERIOD_VIOLATION:
          this.ntf.error('ntf.company_period_violation');
          break;
      }
    }

    if (errResponse.error.message) {
      if (errResponse.error.message.includes(ServerSideErrorCode.COMPANY_PERIOD_VIOLATION)) {
        this.ntf.error('ntf.company_period_violation');
      }
    }
  }

  errorHandlingFor403(req: HttpRequest<any>) {
    if (req.method === 'GET') {
      this.ntf.error('ntf.no_permission');
    } else {
      switch (this.currentCompany()?.contractStatus) {
        case ICompanyContractStatus.IN_DEBT:
          this.ntf.error('ntf.comany_in_debt');
          break;
        case ICompanyContractStatus.SERVICE_PAUSED:
          this.ntf.error('ntf.company_service_paused');
          break;
        case ICompanyContractStatus.SERVICE_FINISHED:
          this.ntf.error('ntf.company_service_finished');
          break;
        default:
          this.ntf.error('ntf.no_permission');
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errResponse: HttpErrorResponse) => {
        const responseCode = errResponse.status;
        if (responseCode === 503) {
          this.ntf.error('ntf.service_unavailable');
        } else if (Math.floor(responseCode / 100) === 5) {
          this.ntf.error('ntf.system_error');
        } else if (responseCode === 409) {
          this.conflictErrorHandling(errResponse);
        } else if (responseCode === 401) {
          this.ntf.error('ntf.authorization_error');
        } else if (responseCode === 403) {
          this.errorHandlingFor403(req);
        } else if (responseCode === 404 && req.url.includes('e-invoice') && req.method === 'GET') {
          return throwError(() => errResponse);
        } else if (Math.floor(responseCode / 100) === 4) {
          this.ntf.error(errResponse.error.message || errResponse.error.detail);
        }
        return throwError(() => errResponse);
      })
    );
  }
}
