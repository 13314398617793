import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { mergeMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      take(1),
      mergeMap((token) => {
        if (
          req.url.includes('restcountries') ||
          req.url.includes('ariregister') ||
          req.url.includes('amazonaws') ||
          req.url.includes('exchangeratesapi') ||
          req.url.includes('form/validators') ||
          req.url.includes('/users/forgot')
        ) {
          return next.handle(req);
        }
        const tokenizeRequest = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });

        return next.handle(tokenizeRequest);
      })
    );
  }
}
