import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from './maintenance.service';

@Component({
  selector: 'maintenance',
  template: ` @if (maintenanceService.maintenanceData$ | async; as maintenanceData) {
  <div>
    <img src="/assets/images/maintenance-loading.gif" />
    <h1>{{ 'text.service_maintenance' | translate }}</h1>
    <p>
      {{ 'text.try_again' | translate }}
      <b>{{ maintenanceData.retryAfter | date: 'HH:mm dd.MM.yyyy':'GMT+4' }}</b
        >.
      </p>
    </div>
  }`,
  styles: [
    `
      h1 {
        font-size: 32px;
        text-align: center;
      }
      p {
        font-size: 18px;
      }
      img {
        margin-left: -85px;
        transform: scale(0.75, 0.75);
      }
    `,
  ],
})
export class MaintenanceComponent implements OnInit {
  constructor(public maintenanceService: MaintenanceService) {}

  ngOnInit(): void {}
}
