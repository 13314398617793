import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, filter, first } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as PermissionSelectors from '../store/selectors/permissions.selectors';
import * as PermissionActions from '../store/actions/permission.actions';
import * as AuthSelectors from '../store/selectors/auth.selectors';
import * as AuthActions from '../store/actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class SuperPrivilegesGuard {
  constructor(private store: Store) {}

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(PermissionSelectors.selectPrivilegesLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    this.store.dispatch(AuthActions.getIdTokenResult());
    this.store
      .select(AuthSelectors.selectUser)
      .pipe(
        filter((user) => !!user),
        first()
      )
      .subscribe((user) => {
        this.store.dispatch(PermissionActions.loadSuperPrivileges({ userUUID: user.uuid }));
      });
    return this.waitForDataToLoad().pipe(map(() => true));
  }
}
