import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from '@shared/tokens';
import { ITawkAPI } from './types';

export const TAWK_API: InjectionToken<{ instance: ITawkAPI }> = new InjectionToken('Tawk_API', {
  providedIn: 'root',
  factory: () => {
    const _window = inject(WINDOW);
    const api = {};
    Object.defineProperty(api, 'instance', {
      get(): ITawkAPI {
        // @ts-ignore
        return _window.Tawk_API || null;
      },
    });

    return api;
  },
});
