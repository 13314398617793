import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, TitleCasePipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'tr-badge',
  template: `
    <div
      class="tr-label"
      [ngClass]="[containerClass ? containerClass : '', theme ? 'label-' + theme : '']"
    >
      @if (localize) {
        {{ text | translate | titlecase }}
      }
      @if (!localize) {
        {{ text | titlecase }}
      }
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
  imports: [TranslateModule, NgClass, TitleCasePipe],
})
export class TrBadgeComponent {
  @Input() text: string;

  @Input() theme: 'primary' | 'accent' | 'warn' = 'primary';

  @Input() localize: boolean;

  @Input() containerClass: string;
}
