import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@shared/tokens';
import { Observable } from 'rxjs';

export interface ScriptLoaderParams {
  async?: boolean;
  defer?: boolean;
  crossorigin?: string;
  integrity?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private window = inject(WINDOW);
  private document = inject(DOCUMENT);

  constructor() {}

  loadScript(scriptUrl: string, params?: ScriptLoaderParams): Observable<void> {
    return new Observable((observer) => {
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = scriptUrl;

      if (params) {
        if (params.async) {
          script.async = true;
        }
        if (params.defer) {
          script.defer = true;
        }
        if (params.crossorigin) {
          script.crossOrigin = params.crossorigin;
        }
        if (params.integrity) {
          script.integrity = params.integrity;
        }
      }

      script.onload = () => {
        observer.next();
        observer.complete();
      };
      script.onerror = (error) => {
        observer.error(error);
      };
      this.document.body.appendChild(script);
    });
  }
}
