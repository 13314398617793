<button
  class="heading d-flex f-space-between f-center-horizontal"
  [attr.aria-expanded]="expanded"
  (click)="toggle()"
>
  <ng-content select=".heading"></ng-content>
  <mat-icon [@indicatorRotate]="expandedState" [@.disabled]="animationIsDisabled"
    >expand_more</mat-icon
  >
</button>
<div [@bodyExpansion]="expandedState" [@.disabled]="animationIsDisabled">
  <ng-content select=".content"></ng-content>
</div>
