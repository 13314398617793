@if (showCompanyInfo) {
  @if (company) {
    <span [matTooltip]="company.name" class="header-symbol">
      {{ company.name | firstLetters: 2 }}
    </span>
    @if (!sidebarMinimized) {
      <div class="company-info-row">
        <span (click)="switchCompany()" [matTooltip]="company.name" class="header-label">{{
          company.name
        }}</span>
        <button (click)="updateCompanyInfo(company.id)" color="primary" mat-flat-button>
          <i class="tr icon-pencil"></i>
        </button>
      </div>
    }
  } @else {
    <span class="header-symbol">
      <mat-progress-spinner
        mode="indeterminate"
        color="primary"
        diameter="16"
      ></mat-progress-spinner>
    </span>
  }
} @else if (authUser) {
  <span [matTooltip]="getAuthUserDisplayName()" class="header-symbol pointer-default">
    {{ getAuthUserLetter() }}
  </span>
  @if (!sidebarMinimized) {
    <div class="company-info-row">
      <span [matTooltip]="getAuthUserDisplayName()" class="header-label pointer-default fs-18">{{
        getAuthUserDisplayName()
      }}</span>
    </div>
  }
}
