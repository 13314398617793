import { Injectable } from '@angular/core';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  routerNavigationAction,
  routerCancelAction,
  routerErrorAction,
  routerNavigatedAction,
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';

import * as fromFileActions from '@store/actions/file.actions';
import * as fromLayoutActions from './layout.actions';

import { LayoutService } from '../layout.service';
import { GlobalProcess } from '../types';

@Injectable()
export class LayoutEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private layoutService: LayoutService
  ) {}

  setProcessFileCreating$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileActions.createDocument),
      map(() => fromLayoutActions.addPendingProcess({ process: GlobalProcess.FileCreating }))
    )
  );

  setProcessFileUploading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileActions.uploadFile),
      map(() => fromLayoutActions.addPendingProcess({ process: GlobalProcess.FileUploading }))
    )
  );

  removeFileCreatingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileActions.createDocumentSuccess, fromFileActions.createDocumentFailure),
      map(() => fromLayoutActions.removePendingProcess({ process: GlobalProcess.FileCreating }))
    )
  );

  removeFileUploadingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFileActions.uploadFileSuccess, fromFileActions.uploadFileFailure),
      map(() => fromLayoutActions.removePendingProcess({ process: GlobalProcess.FileUploading }))
    )
  );

  navigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      map(() => fromLayoutActions.setLoading({ loading: true }))
    )
  );

  navigationCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction, routerCancelAction, routerErrorAction),
      map(() => fromLayoutActions.setLoading({ loading: false }))
    )
  );

  navigated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        switchMap(() =>
          this.layoutService.state$.pipe(
            take(1),
            tap((state) => {
              if (state.header.label && state.header.label.hideOnNavigation) {
                this.layoutService.setHeaderLabel(null);
              }
              if (state.sidebar.minimizeOnNavigation) {
                this.layoutService.minimizeSidebar();
              }
            })
          )
        )
      ),
    { dispatch: false }
  );
}
