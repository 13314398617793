import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpParameterCodec,
} from '@angular/common/http';
import { Observable } from 'rxjs';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable()
export class RSQLEncoderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.params.has('rsql')) {
      let params = new HttpParams({
        encoder: new CustomEncoder(),
      });

      request.params.keys().forEach((key) => {
        params = params.set(key, request.params.get(key));
      });

      request = request.clone({ params });
      return next.handle(request);
    }
    return next.handle(request);
  }
}
