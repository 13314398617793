<div class="main-layout-container">
  <app-main-layout-header
    [theme]="theme$ | async"
    [label]="headerLabel$ | async"
    [menuItems]="headerMenuItems$ | async"
    [isViewerActive]="isViewerActive$ | async"
    [showCompanyInfo]="showCompanyInfo$ | async"
    [sidebarMinimized]="sidebarMinimized$ | async"
    [selectedCompany]="selectedCompany()"
    (action)="layoutActionHandler($event)"
  />

  @if (!sidebarHidden) {
    <app-main-layout-sidenav
      [@inOutPaneAnimation]="!disableTransition"
      [basePath]="basePath$ | async"
      [authUser]="authUser$ | async"
      [company]="selectedCompany()"
      [showCompanyInfo]="showCompanyInfo$ | async"
      [sidebarMinimized]="sidebarMinimized$ | async"
      [menuItems]="menuItems$ | async"
      (accordionItemToggle)="onAccordionItemToggle()"
      (sidebarToggle)="onSidebarToggle()"
      (action)="layoutActionHandler($event)"
    />
  }

  <main id="tr-content" class="main-layout-content">
    @if (loading$ | async) {
      <div @layoutLoading class="content-loader">
        <div [loaderOverlay]="true" style="width: 100%; height: 100%"></div>
      </div>
    }

    <div class="content-wrapper">
      <ng-content></ng-content>
    </div>
  </main>
</div>
