import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({headers: request.headers.set('Accept-Language', localStorage.getItem('lang'))});
        return next.handle(request);
    }
}