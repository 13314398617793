import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetters',
  standalone: true,
})
export class FirstLettersPipe implements PipeTransform {
  transform(value: string, count?: number): string {
    if (value && typeof value === 'string') {
      return value
        .split(' ')
        .map((word) => word[0])
        .slice(0, count || value.length)
        .join('')
        .toUpperCase();
    }
    return '';
  }
}
