import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { LabelFacadeService } from '@domains/label';
import { findAllNestedValues } from '@shared/utils';

export const unsavedLabelsInterceptor: HttpInterceptorFn = (req, next) => {
  const labelFacade = inject(LabelFacadeService);
  if (allowedMethods().includes(req.method) && hasLabelsField(req.body)) {
    return next(req).pipe(
      tap((response: HttpResponse<any>) => {
        if (response instanceof HttpResponse && response.ok) {
          const labels: Array<Array<string>> = findAllNestedValues(req.body, 'labels');
          if (labels.length) {
            labelFacade
              .getLabelNames()
              .pipe(take(1))
              .subscribe((labelNames) => {
                const uniqueArray = Array.from(new Set(labels.flat()));
                if (!uniqueArray.every((label) => labelNames.includes(label))) {
                  labelFacade.refetchLabels();
                }
              });
          }
        }
      })
    );
  } else {
    return next(req);
  }
};

function allowedMethods() {
  return ['POST', 'PUT', 'PATCH'];
}

function hasLabelsField(body: any) {
  return findAllNestedValues(body, 'labels').length > 0;
}
