import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { LowerCasePipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  CURRENT_COMPANY_STORE,
  CompanyPopupsService,
  ICompany,
} from '@src/app/_domains/company';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderOverlayDirective } from '@src/app/_shared/global-directives/loader-overlay.directive';
import { TrDatePipe } from '@src/app/_shared/global-pipes/tr-date.pipe';
import { SharedModule } from '@src/app/_shared/shared.module';

@Component({
  standalone: true,
  selector: 'company-closing-period-status',
  imports: [
    NgClass,
    LowerCasePipe,
    TranslateModule,
    MatButtonModule,
    MatTooltipModule,
    LoaderOverlayDirective,
    SharedModule,
  ],
  template: `
    @if (loading) {
      <div [loaderOverlay]="true" loaderIndicatorSize="small">
        <div style="height: 24px; width: 24px"></div>
      </div>
    } @else {
      <button
        (click)="showContractStatusHistory()"
        class="closing-period-btn no-animate"
      >
      <i class="tr icon-lock"></i>
      @if (company.periodEnd) {
        <i class="tr icon-arrow-right"></i>
        <p class="closing-period">{{trDatePipe.transform(company.periodEnd)}}</p>
      } @else {
        <i class="tr icon-plus"></i>
      }
      </button>
    }
  `,
  styles: [
    `
      .closing-period-btn {
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
        display: flex;
        gap: 6px;
        height: 32px;
        margin-right: 14px;
        width: unset;
        border: 1px solid black;
        border-radius: 4px;
      }

      .closing-period {
        margin: 0;
        margin-top: 2px;
        font-size: 12px;
      }

      .icon-arrow-right {
        color: black !important;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClosingPeriodStatus implements OnInit, OnChanges {
  @Input() company: ICompany;
  loading: boolean = false;
  trDatePipe = new TrDatePipe();
  private readonly companyStore = inject(CURRENT_COMPANY_STORE);

  constructor(
    private popupsService: CompanyPopupsService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.company.currentValue &&
      changes.company.previousValue &&
      changes.company.currentValue?.uuid !== changes.company.previousValue?.uuid
    ) {
      this.loading = false;
    }
  }

  showContractStatusHistory(): void {
    this.popupsService.showClosingPeriodPopup(this.company).subscribe(
      res => {
        if (res.actionHappened && res.updatedDate) {
          this.companyStore.updatePeriodEnd(res.updatedDate)
        }
      }
    );
  }
}
