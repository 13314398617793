import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MaintenanceService } from '../_shared/maintenance.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(private maintenanceService: MaintenanceService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errResponse: HttpErrorResponse) => {
        const responseCode = errResponse.status;
        const title = errResponse.error?.title;
        const retryAfter = errResponse.error?.retryAfter;
        const type = errResponse.error?.type;
        const contentType = errResponse.headers.get('content-type');

        if (
          responseCode === 503 &&
          contentType === 'application/problem+json' &&
          type === 'problem:gateway:maintenance' &&
          retryAfter
        ) {
          this.maintenanceService.setMaitenanceData({ status: true, title, retryAfter });
        }

        return throwError(errResponse);
      })
    );
  }
}
