import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { ScriptLoaderService } from '@shared/global-services/script-loader.service';

import { TAWK_API } from './tokens';
import { interval, shareReplay, switchMap, timer } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Auth } from '@models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class TawkApiService {
  private readonly tawkScriptUrl = `https://embed.tawk.to/${environment.tawk.apiKey}`;
  private readonly scriptLoader = inject(ScriptLoaderService);
  private readonly tawkApi = inject(TAWK_API);

  private readonly maxWaitTime$ = timer(10000);
  private readonly checkApiExists$ = interval(100).pipe(
    map(() => this.tawkApi.instance),
    filter((apiExists) => apiExists !== null),
    filter((apiExists) => apiExists.onLoaded),
    takeUntil(this.maxWaitTime$),
    take(1),
    shareReplay(1)
  );

  private instance = this.tawkApi.instance;

  constructor() {}

  initialize(): void {
    this.loadScript()
      .pipe(switchMap(() => this.checkApiExists$))
      .subscribe((api) => {
        this.instance = api;
      });
  }

  setUserData(user: Auth.User): void {
    this.checkApiExists$.pipe(take(1)).subscribe(() => {
      const fullName = this.getUserFullName(user);
      const attributes: { [key: string]: string } = {};

      if (user?.email) {
        attributes.email = user.email;
      }

      if (fullName) {
        attributes.name = fullName;
      }

      this.instance?.setAttributes(attributes);
      this.minimize();
    });
  }

  maximize() {
    this.instance?.maximize();
  }

  minimize() {
    this.instance?.minimize();
  }

  toggleVisibility() {
    this.instance?.toggleVisibility();
  }

  private loadScript() {
    return this.scriptLoader.loadScript(this.tawkScriptUrl, {
      async: true,
      crossorigin: '*',
    });
  }

  private getUserFullName(user: Auth.User): string | null {
    if (!user) {
      return null;
    }

    let fullName = '';

    if (user.firstName) {
      fullName += user.firstName;
    }

    if (user.lastName) {
      fullName += ` ${user.lastName}`;
    }

    return fullName ? fullName.trim() : user?.email;
  }
}
