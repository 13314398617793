import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private maintenanceData$$ = new BehaviorSubject<any>({ status: false });
  maintenanceData$ = this.maintenanceData$$.asObservable();

  setMaitenanceData(maintenanceData: { status: boolean; title: string; retryAfter: string }) {
    this.maintenanceData$$.next(maintenanceData);
  }
}
