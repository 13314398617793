import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as PermissionSelectors from '@store/selectors/permissions.selectors';
import { filter, map, take } from 'rxjs/operators';

@Directive({
  selector: '[ngAccess]',
  standalone: true,
})
export class NgAccessDirective implements OnChanges {
  @Input() ngAccess: {
    context: string;
    level: number;
  };

  constructor(
    private store: Store,
    private tmpRef: TemplateRef<null>,
    private viewRef: ViewContainerRef
  ) {}

  private render() {
    this.viewRef.createEmbeddedView(this.tmpRef);
  }

  private clear() {
    this.viewRef.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngAccess) {
      if (!this.ngAccess || !this.ngAccess.context || !this.ngAccess.level) {
        this.render();
        return;
      }

      this.store
        .select(PermissionSelectors.selectState)
        .pipe(
          filter((state) => !!state.privilegesLoaded),
          map((state) => state.privileges),
          take(1)
        )
        .subscribe((privileges) => {
          if (privileges) {
            const { context, level } = this.ngAccess;
            const contexts = context.split(/[|&]/);
            const method = context.includes('&') ? 'every' : 'some';
            const hasAccess = contexts[method]((ct) => {
              return privileges[ct]?.[level - 1] == 1;
            });
            if (hasAccess) {
              this.render();
            } else {
              this.clear();
            }
          }
        });
    }
  }
}
