import { NgModule } from '@angular/core';
import { TrigonAccordionComponentItem } from './trigon-accordion-item.component';
import { MatIconModule } from '@angular/material/icon';
import { TrigonAccordion } from './trigon-accordion';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [TrigonAccordionComponentItem, TrigonAccordion],
  imports: [MatIconModule, SharedModule],
  exports: [TrigonAccordionComponentItem, TrigonAccordion],
})
export class TrigonAccordionModule {}
