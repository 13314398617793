import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TrInterceptor } from '../_shared/global-services/tr-interceptor';
let x = `/aggregator/transactions/company/742e479e-6d0c-47f2-b3a4-1a5228751e87/paymentMethod/d82bfb7e-22f3-48f2-b80c-d0102bd5dcaa?pageIndex=0&pageSize=50&currency=EUR&from=2022-01-01&to=2022-02-16`;

export const mockInterceptors: Provider[] = [
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useValue: new TrInterceptor({
  //     method: 'GET',
  //     endpoint: `/aggregator/transactions/company/742e479e-6d0c-47f2-b3a4-1a5228751e87/payment_method/d82bfb7e-22f3-48f2-b80c-d0102bd5dcaa?currency=EUR&from=2022-01-01&to=2022-02-16&pageIndex=0&pageSize=10&unlockedOnly=false`,
  //     handler: (data, endpoint) => {
  //       return {
  //         data: [
  //           {
  //             uuid: '82322c0b-a8dd-4f21-b3f7-6b2022ac7853',
  //             code: 'LAE.00197',
  //             date: '2022-02-11',
  //             partnerType: 'CLIENT',
  //             partnerUUID: '3b51dfbf-7903-497e-81f6-4530b1c2b0ed',
  //             description: 'Laekumine',
  //             amount: {
  //               amount: 52240.8,
  //               currency: 'EUR',
  //             },
  //             type: 'ACCRUAL',
  //             invoiceType: null,
  //             invoiceUUID: null,
  //             financialEntryAccountUUID: null,
  //             financialEntryUUID: null,
  //             isPrepayment: false,
  //             noLedgerEntry: false,
  //             locked: false,
  //             source: 'MANUAL',
  //           },
  //         ],
  //         pageIndex: 0,
  //         totalPages: 1,
  //         totalElementsCount: 1,
  //       };
  //     },
  //   }),
  //   multi: true,
  // },
];
