import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { AuthInterceptor } from './auth-interceptor';
import { mockInterceptors } from './mock-interceptors';
import { ErrorInterceptor } from './error-interceptor';
import { LanguageInterceptor } from './lang-interceptor';
import { MaintenanceInterceptor } from './maintenance-interceptor';
import { CacheInterceptor } from './cache-interceptor';
import { RSQLEncoderInterceptor } from './rsql-encoder.interceptor';
import { CompanyIdInterceptor } from './company-id.interceptor';
import { CACHE_STORAGE, RefreshCacheStorage } from '@shared/cache';

export const httpInterceptorProviders: Provider[] = [
  ...mockInterceptors,
  { provide: CACHE_STORAGE, useClass: RefreshCacheStorage },
  { provide: HTTP_INTERCEPTORS, useClass: CompanyIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  // { provide: RequestCache, useClass: RequestCacheWithMap },
  // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RSQLEncoderInterceptor, multi: true },
];
