import { effect, inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { VATRateActions } from '@domains/vat';
import { CURRENT_COMPANY_STORE } from '@domains/company';
import { TawkApiService } from '@shared/third-party/tawk/tawk-api.service';

import * as UtilsActions from '@store/actions/util.actions';
import * as AuthSelectors from '@store/selectors/auth.selectors';
import * as AccountActions from '@store/actions/account.actions';
import { getIdTokenResult } from '@store/actions/auth.actions';
import { selectUserTokenLoaded } from '@store/selectors/auth.selectors';

@Injectable()
export class AppInitService {
  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly companyID = this.companyStore.id;
  private readonly company = this.companyStore.entity;
  private readonly authUser$ = this.store.select(AuthSelectors.selectUser);

  constructor(
    private store: Store,
    private tawkApiService: TawkApiService
  ) {
    effect(
      () => {
        const company = this.company();
        if (company) {
          this.store.dispatch(AccountActions.loadLabels());
          this.store.dispatch(
            AccountActions.Local.load({
              companyUuid: this.companyID(),
              pageSize: 1000,
            })
          );

          this.store.dispatch(
            AccountActions.Global.load({
              pageSize: 10000,
              plsFormat: company?.profitAndLossSheetFormat,
            })
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  Init() {
    // Initialize Tawk API
    this.tawkApiService.initialize();

    // Load all countries
    this.store.dispatch(UtilsActions.loadListCountries());

    // Load all currencies
    this.store.dispatch(UtilsActions.loadCurrencies());

    // Load user by token.
    this.store
      .pipe(
        select(selectUserTokenLoaded),
        filter((loaded) => !loaded)
      )
      .subscribe(() => this.store.dispatch(getIdTokenResult()));

    // fetch data which is required authentication.
    this.authUser$.subscribe((authUser) => {
      this.tawkApiService.setUserData(authUser);

      if (authUser) {
        this.store.dispatch(VATRateActions.loadAll());
        this.store.dispatch(
          AccountActions.Global.load({
            pageSize: 10000,
          })
        );
      }
    });

    return of(true);
  }
}
