import { NgModule } from '@angular/core';
import { CoreComponent } from './core.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import * as fromBureaus from '../store/reducers/bureau.reducer';
import * as fromPermissions from '../store/reducers/permissions.reducer';
import * as fromInboundDocument from '../store/reducers/inbound-document.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MatExpansionModule } from '@angular/material/expansion';
import { TrigonAccordionModule } from '../_shared/trigon-accordion/trigon-accordion.module';
import { SharedModule } from '../_shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { OrgEffects } from '../store/effects/organization.effects';
import { LoadingModule } from '../_shared/loading/loading.module';
import { TrStoreModule } from '../_shared/global-modules/tr-store/tr-store.module';
import { PermissionEffects } from '../store/effects/permissions.effects';
import { PermissionsModule } from '../permissions/permissions.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MainLayoutComponent } from '@app/_layouts/main-layout/main-layout.component';

@NgModule({
  declarations: [CoreComponent],
  imports: [
    RouterModule,
    LoadingModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatListModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTooltipModule,
    ScrollingModule,
    MatExpansionModule,
    ReactiveFormsModule,
    TrigonAccordionModule,
    LoadingModule,
    TrStoreModule.forFeature(fromBureaus, fromPermissions, fromInboundDocument),
    EffectsModule.forFeature([OrgEffects, PermissionEffects]),
    PermissionsModule,
    SharedModule,
    MainLayoutComponent,
  ],
})
export class CoreModule {}
