import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { matExpansionAnimations, MatExpansionPanelState } from '@angular/material/expansion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { TrigonAccordion } from './trigon-accordion';

@Component({
  selector: 'app-trigon-accordion-item',
  templateUrl: './trigon-accordion-item.component.html',
  styleUrls: ['./trigon-accordion-item.component.scss'],
  providers: [UniqueSelectionDispatcher],
  animations: [matExpansionAnimations.bodyExpansion, matExpansionAnimations.indicatorRotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrigonAccordionComponentItem extends CdkAccordionItem implements OnInit {
  _sidebarMinimized: boolean;
  animationIsDisabled = true;

  @Input()
  set sidebarMinimized(sidebarMinimized: boolean) {
    this._sidebarMinimized = sidebarMinimized;
    if (sidebarMinimized) {
      this.close(); // Manually close the accordion item
    }
  }
  get sidebarMinimized(): boolean {
    return this._sidebarMinimized;
  }

  @Output() toggled = new EventEmitter<void>();

  constructor(
    @Optional() @SkipSelf() accordion: TrigonAccordion,
    _changeDetectorRef: ChangeDetectorRef,
    _expansionDispatcher: UniqueSelectionDispatcher
  ) {
    super(accordion, _changeDetectorRef, _expansionDispatcher);
    this.accordion = accordion;
  }

  get expandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  override toggle(): void {
    super.toggle();
    this.toggled.emit();
  }

  ngOnInit(): void {
    // This is a hack to prevent the accordion from opening when the sidebar is minimized
    setTimeout(() => {
      this.animationIsDisabled = false;
    }, 100);
  }
}
